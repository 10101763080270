<template>
  <section class="section-main">
    <div class="section-main__content">
      <inline-svg :src="require('../../assets/icon/svg/card-success.svg')" />
      <div class="section-main__title"><h1>信用卡驗證成功</h1></div>
      <p>裝置準備就緒，充電進度將傳送至您的手機</p>
    </div>
    <div class="section-main__action">
      <button
        class="button is-fullwidth is-fill is-rounded router-link"
        @click="handleClick()"
        type="button"
      >
        開始充電
      </button>
    </div>
  </section>
</template>
<script>
export default {
  name: "CardAuthSuccessMessage",
  methods: {
    handleClick() {
      this.$router.replace({
        name: "CheckConnector",
        params: {
          connectorId: this.$route.params.connectorId,
          stationId: this.$route.params.stationId,
        },
      });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    this.scrollToTop();
  },
};
</script>
